import logo from './img/logo_resized.png';
import paperclip from './img/3d_paperclipLoop.gif';
import './App.css';

const isMobile = window.innerWidth <= 500;


function App() {
  if (isMobile) {
    return (
      <div className="Mobile-view">
        <img className="logo-mobile" src={logo} alt="logo"/>
        <img className="paperclip-mobile" src={paperclip}/>
        <p className="newspaper_text-mobile">Subscribe to our ARCHIVE</p>
        <form action="https://formsubmit.co/farhad.latif@hotmail.com" method="POST">
          <input className="email_input" type="email" name="Email" placeholder="My e-mail"></input>
          <button className="subscribe_btn" type="submit">Subscribe</button>
        </form>
      </div>
    )
  }
  else return (
    <div className="Desktop-view">
      <img className="logo" src={logo} alt="logo"/>
      <img className="paperclip" src={paperclip}/>
      <p className="newspaper_text">Subscribe to our ARCHIVE</p>
      <form action="https://formsubmit.co/farhad.latif@hotmail.com" method="POST">
        <input className="email_input" type="email" name="Email" placeholder="My e-mail"></input>
        <button className="subscribe_btn" type="submit">Subscribe</button>
      </form>
    </div>
  );
}

export default App;
